import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layout/primary';
import {
  AboutCta,
  Hero,
  HowWeWork,
  LibraryBlock,
  AboutCarousel,
  Ram,
  Timeline,
  ResponsiveContext,
} from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';

export const query = graphql`
  {
    prismic {
      content: allAbout_uss {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            hero_image
            how_we_work {
              heading
              text
              stat_value
              stat_context
              stat_source
              image
            }
            testimonials {
              name
              job_title
              quote
              image
            }
            testimonials_background
            publications {
              publication {
                ... on PRISMIC_Publication {
                  _meta {
                    id
                    uid
                  }
                  title
                  type
                  image
                  summary
                }
              }
            }
          }
        }
      }
      timeline: allAbout_uss {
        edges {
          node {
            timeline {
              image
              text
              year
            }
          }
        }
      }
      publications: allPublications(where: { type: "Press Release" }, sortBy: date_DESC, first: 3) {
        edges {
          node {
            _meta {
              id
              uid
              firstPublicationDate
            }
            date
            title
            type
            image
            summary
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const howWeWork = content.how_we_work.map((item, index) => {
    const id = `howWeWork-${index}`;

    return {
      id,
      heading: item.heading,
      text: item.text,
      stat: item.stat_value,
      statContext: item.stat_context,
      statSub: item.stat_source,
      image: item.image,
    };
  });
  const testimonials = content.testimonials.slice(0, 3).map((item, index) => {
    const id = `testimonials-${index}`;

    return {
      id,
      authorName: item.name,
      authorTitle: item.job_title,
      authorQuote: item.quote,
      authorImage: item.image,
    };
  });
  const libraryCards = content.publications.map((pub) => {
    const excerpt =
      (pub.publication.summary && pub.publication.summary[0].text) ||
      (pub.publication.text && pub.publication.text[0].text) ||
      '';
    const image = pub.publication.image ? pub.publication.image.thumbnail : '';
    return {
      id: pub.publication._meta.id,
      category: pub.publication.type.replace('_', ' '),
      title: pub.publication.title,
      to: `/library/${pub.publication._meta.uid}`,
      image,
      excerpt: excerpt.length > 140 ? `${excerpt.substring(0, 140)}…` : excerpt,
      linkText: 'Read more',
    };
  });

  const timelineData = data?.prismic.timeline.edges[0].node.timeline;

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.hero_image && content.hero_image.url}
    >
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <>
              <Hero
                isAbout
                imageMobile={content.hero_image.mobile.url}
                imageDesk={content.hero_image.url}
                heroHeader={content.hero_header}
                heroCopy={content.hero_copy}
                htmlSerializer={htmlSerializer}
              />
              <HowWeWork
                heading="How We Work"
                cardData={howWeWork}
                htmlSerializer={htmlSerializer}
              />
              <Ram color="E63D2F" />
              <AboutCarousel
                backgroundImage={content.testimonials_background.url}
                carouselContent={testimonials}
              />
              <LibraryBlock
                eyebrowText="Library"
                titleText="Dive Deep into POWER"
                bodyText="Want to learn more about POWER and how we’re making a difference in the industries we serve? Check out our latest news."
                libraryCards={libraryCards}
                exploreTag="There's plenty more where that came from—including projects, insights, articles, whitepapers and more."
                Link={Link}
              />
              <Timeline size={size} data={timelineData} />
              <AboutCta
                Link={Link}
                ctaHeadingOne="Looking for a specific POWER office or the one nearest you?"
                ctaToOne="/locations"
                ctaButtonOne="See Our Locations"
                ctaHeadingTwo="Want to see what comes with a career at POWER?"
                ctaToTwo="/careers"
                ctaButtonTwo="Careers"
              />
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
